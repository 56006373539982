<div class="col-md-12">
  <mat-table [id]="isGeoFence ? geoFencingTable : ''" #table [dataSource]="businessPartnerDataSource"
             *ngIf="states && tags" class="apply-border">
    <ng-container matColumnDef="BpName">
      <mat-header-cell class="sortable" (click)="nameSortBy('businessPartnerLegalName')" class="sortable"
                       *matHeaderCellDef> BP Name </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 2 || (element.businessPartnerStatus == 3 && isPopup) }"> {{element.businessPartnerLegalName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Country">
      <mat-header-cell (click)="countrySortBy()" class="sortable" *matHeaderCellDef>
        Country
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 3 && isPopup }">
        <span *ngFor="let country of element.locations;first as isFirst">
           <span *ngIf="!isFirst">, </span><span> {{getCountryName(country.country) ? (country.country | titlecase) :
          "USA"}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PrimaryState">
      <mat-header-cell *matHeaderCellDef>
        Primary State
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 3 && isPopup }">
        <span *ngFor="let location of element.locations;">
           <span *ngIf="location.isPrimary &&  location.stateId == null"> {{ location.state | titlecase }}</span>
           <span *ngIf="location.isPrimary && location.stateId != null"> {{location.primaryState.name}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="PrimaryLocation">
      <mat-header-cell *matHeaderCellDef>
        Primary Location
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 3 && isPopup }">
        <span *ngFor="let location of element.locations;">
           <span *ngIf="location.isPrimary && location.stateId == null"> {{ location.city | titlecase }}</span>
           <span *ngIf="location.isPrimary && location.cityId != null"> {{location.primaryCity.name}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ServicableState">
      <mat-header-cell (click)="stateSortBy()" class="sortable" *matHeaderCellDef>
        Servicable State
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 2 || (element.businessPartnerStatus == 3 && isPopup) }">
        <span *ngIf="element.isNationwide">Nationwide</span>
        <span *ngIf="!element.isNationwide">
          <span *ngFor="let state of states  | filterByStateId:element.serviceableStates;last as isLast;first as isFirst">
            <span *ngIf="!isFirst">, </span><span> {{state.name}}</span>
          </span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CityName">
      <mat-header-cell *matHeaderCellDef>Servicable City </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.isNationwide">Nationwide</span>
        <span *ngIf="!element.isNationwide">
        <span *ngFor="let sc of element.serviceableCities; first as isFirst">
          <span *ngIf="!isFirst">, </span><span> {{sc.city.name}}</span>
        </span>
      </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="City">
      <mat-header-cell *matHeaderCellDef>
        Servicable City
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.isNationwide">Nationwide</span>
        <span *ngIf="!element.isNationwide">
        <span
        *ngFor="let cty of element.serviceableCities | filterByCityId:geoFencedCities;last as isLast;first as isFirst">
        <span *ngIf="!isFirst">, </span><span> {{cty.name}}</span>
      </span>
    </span>
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="SolutionPillar">
        <mat-header-cell *matHeaderCellDef> Solution Pillar </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span
                *ngFor="let pilar of solutionPilars | filterBySoulthinPillarId: element.solutionPilarsBusinessPartners; first as isFirst"><span
                    *ngIf="!isFirst">, </span>{{pilar.solutionPilarName}}
            </span>
        </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="Miles">
      <mat-header-cell [hidden]="isGeoFence" *matHeaderCellDef>
        Miles
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [hidden]="isGeoFence">
         <span
        *ngFor="let cty of element.serviceableCities | filterByCityId:geoFencedCities;last as isLast;first as isFirst">
        <span *ngIf="!isFirst">, </span><span> {{cty.distance| number : '1.2-2'}}</span>
      </span>
      </mat-cell>
      <mat-cell *matCellDef="let element">
        {{getGeoFenceDistance(element)}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="TagName">
      <mat-header-cell *matHeaderCellDef> Tag </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 2 || (element.businessPartnerStatus == 3 && isPopup) }">
        <span *ngFor="let tag of tags | filterByTagId: element.businessPartnerTags; first as isFirst">
          <span *ngIf="!isFirst">, </span><span> {{tag.name}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="overallRating">
      <mat-header-cell *matHeaderCellDef> Rating </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 2 || (element.businessPartnerStatus == 3 && isPopup) }">
        <span *ngIf="element.overallRating">
          <span *ngIf="element.overallRating == 'Not rated'">{{element.overallRating}}</span>
          <span *ngIf="element.overallRating != 'Not rated'">{{element.overallRating | number:'1.2-2'}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="ScorecardRank">
        <mat-header-cell *matHeaderCellDef> Scorecard Rank </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.scoreCard}} </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="Status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'highlight-status': element.businessPartnerStatus == 2 || (element.businessPartnerStatus == 3 && isPopup) }">
        <div [ngSwitch]="element.businessPartnerStatus">
          <ng-template [ngSwitchCase]="0">In Process </ng-template>
          <ng-template [ngSwitchCase]="1">Approved - In Good Standing</ng-template>
          <ng-template [ngSwitchCase]="3">Approved - Awaiting Renewed COI</ng-template>
          <ng-template [ngSwitchCase]="2">Do Not Use/Accept</ng-template>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="AgreementType">
      <mat-header-cell (click)="agreementTypeSortBy()" *matHeaderCellDef>
        Agreement Type
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngFor="let agreement of element.agreements;first as isFirst">
          <span *ngIf="!isFirst">, </span><span> {{getAgreementType(agreement.agreementType)}}</span>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="isPopup">
          <button id="selectButton" mat-icon-button type="button" color="primary" (click)="closePopup(element)" title="Select Business Partner">
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <button id="kebabMenu" class="businessPartnerKebabMenu" mat-icon-button [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu" [attr.data-id]="element.id" *ngIf="!isPopup">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngIf="!isPopup && !isGeoFence">
            <button mat-menu-item id="editBtn" color="primary" type="button" (click)="navigateToBp(element.id)">
              <mat-icon>{{hasReadOnlyAccess? 'search' : 'edit'}}</mat-icon>
              <span>{{hasReadOnlyAccess? 'View' : 'Details/Edit'}}</span>
            </button>
          </ng-container>
          <ng-container *ngIf="!isPopup && isGeoFence">
            <button id="viewButton" mat-menu-item color="primary" type="button"
                    (click)="!isPopup ? viewBp(element.id) : closePopup(element)">
              <mat-icon>search</mat-icon>
              <span>View</span>
            </button>
          </ng-container>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
    <mat-row class="geoFenceResultRow" *matRowDef="let row; columns: getDisplayedColumns();"></mat-row>
  </mat-table>
  <div *ngIf="!(businessPartnerDataSource && businessPartnerDataSource.data.length > 0) && !isBPDataLoading"
       class="no-records">
    No records found
  </div>
  <div *ngIf="isBPDataLoading" class="no-records">
    ...Loading the Business Patner records. Please wait
  </div>
</div>

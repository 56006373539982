export class BaseEntityCreate {
        createdDate: Date;
        softDelete: boolean;
        createdBy: string;
        createdByEmployeeId?: string;
}
export class ServiceRole extends BaseEntityCreate {
        serviceRoleId: string;
        serviceRoleName: string;
        serviceRoleDescription: string;
        epicorPartNumberId: string;
        serviceRoleHourlyRate: number;
        projectControl: ProjectControl;
        employeeServiceRoles: Array<any>;
        componentRateApproverId?: string;
        serviceRoleEstimateTypes?: Array<ServiceRoleEstimateType>;
        enableMultipleOverrides?: boolean;
}
export class ProjectControl extends BaseEntityCreate {
        projectControlId: string;
        projectControlName: string;
        projectControlDescription: string;
        projectControlHourlyRate: number;
        projectControlProjectControlRates: Array<ProjectControlProjectControlRates>;
}
export class ProjectControlProjectControlRates {
        projectControlProjectControlRateId: string;
        projectControlId: string;
        projectControlRate: ProjectControlRate;
        projectControlRateId: string;
}
export class ProjectControlRate extends BaseEntityCreate {
        projectControlRateId: string;
        projectControlRateName: string;
        projectControlRateDescription: string;
        projectControlRateMinHours: number;
        projectControlRateMaxHours: number;
        projectControlRatePercentage: number;
        projectControl: ProjectControl;
        projectControlId: string;
}
export class ServiceRoleEstimateType {
        serviceRoleId: string;
        serviceEstimateType: number;
        Id: string;
}

<mat-accordion *ngIf="items" [multi]="true" [displayMode]="'flat'">
  <button mat-button type="button" title="expand items" (click)="accordion.openAll()">Expand All</button>
  <button mat-button type="button" title="collapse items" (click)="accordion.closeAll()">Collapse All</button>

  <!-- <button mat-icon-button type="button" title="expand all items" (click)="accordion.openAll()"><mat-icon>expand_more</mat-icon></button>
  <button mat-icon-button type="button" title="collapse all items" (click)="accordion.closeAll()"><mat-icon>expand_less</mat-icon></button> -->
  <br>
  <button mat-icon-button type="button" *ngIf="phase === 'Plan'" (click)="addPlanItem.emit()" color="primary"
    title="Add Plan Item">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="phase === 'Execute'" (click)="addExecuteItem.emit()" color="primary"
    title="Add Execute Item">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="phase === 'Close'" (click)="addCloseItem.emit()" color="primary"
    title="Add Close Item">
    <mat-icon>add</mat-icon>
  </button>
  <div [formGroup]="formGroup" style="width:100%;">
    <div *ngIf="items.length > 0" [formArrayName]="formArrayName">
      <div *ngFor="let item of formItems;let i = index" [formGroupName]="i" cdkDrag>

        <mat-expansion-panel [expanded]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="drag-handle" cdkDragHandle>
                <mat-icon>drag_indicator</mat-icon>
              </p>
              <p style="width:100%;font-weight: bold;" [class.red]="!item.valid">
                {{items.controls[i].value['activity']}}
                <span style="float: right;font-weight: bold;margin-left:15px;">
                  Price:
                  {{items.controls[i].value['estimatiedPrice'] | currency :'USD' :'symbol'}}
                </span>
                <span style="float: right;font-weight: bold;">
                  Hours:
                  {{(convertStringToInt(items.controls[i].value['quantity']) * convertStringToInt(items.controls[i].value['multiplier'])).toFixed(2) }}
                </span>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-divider></mat-divider>
          <p class="header5">{{phase}} Item {{ i + 1}}
          </p> <button mat-icon-button type="button" (click)="removeControl(items,i)">
            <mat-icon class="md-18 cursor">clear</mat-icon>
          </button>
          <div class="col-xl-10" *ngIf="!isMutilplierReadOnly">
            <div class="">
              <div class="col-xl-4">
                <mat-checkbox formControlName="isInstallation">Is Installation?</mat-checkbox>
                <mat-checkbox formControlName="isSpecialApplicationAndPremierService">Is Special Application And Premier
                  Service?</mat-checkbox>
              </div>
            </div>
          </div>
          <div class="col-xl-10">
            <div class="row">
              <div class="col-xl-4">
                <mat-form-field>
                  <input type="text" matInput placeholder="Activity" formControlName="activity" required />

                  <mat-error *ngIf="items.at(i).hasError('required', ['activity'])">
                    Activity is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-xl-4">
                  <mat-checkbox formControlName="isPrevailingWage">Is Prevailing Wage?</mat-checkbox>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-xl-1">
                <mat-form-field>
                  <input type="number" matInput placeholder="Quantity" formControlName="quantity" step=".25" required />
                  <mat-error *ngIf="items.at(i).hasError('required', ['quantity'])">
                    Quanity is required</mat-error>
                  <mat-error *ngIf="items.at(i).hasError('pattern', ['quantity'])">
                    Quanity must be a whole number</mat-error>
                    <mat-error *ngIf="items.at(i).hasError('stepCount', ['quantity'])">
                      {{formItems.at(i).controls['quantity'].errors.stepCount.message}}
                    </mat-error>
                </mat-form-field>
              </div>

              <div class="col-xl-2">
                <mat-form-field>
                  <input [id]="phase.toLowerCase()+'Item'+(i+1)" matInput placeholder="Service Role"
                         [value]="getServiceRole(item)" disabled />
                </mat-form-field>
              </div>
              <div class="col-xl-2">
                <mat-form-field>
                  <span matPrefix>$&nbsp;</span>
                  <input readonly="isMutilplierReadOnly" matInput placeholder="Estimated Price" currencyMask
                    formControlName="estimatiedPrice" tabindex="-1" />
                </mat-form-field>
              </div>
              <div class="col-xl-1">
                <mat-form-field>
                  <span matPrefix>$&nbsp;</span>
                  <input type="string" matInput placeholder="Billed Rate" formControlName="billedRate" currencyMask
                    readonly />
                </mat-form-field>
              </div>
              <div class="col-xl-1">
                <mat-form-field>
                  <input matInput type="number" formControlName="multiplier" placeholder="Multiplier" step=".1" />
                  <mat-error *ngIf="items.at(i).hasError('nonZero',['multiplier'])">Zero multiplier is not allowed
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-xl-1">
                <mat-form-field>
                  <span matPrefix>$&nbsp;</span>
                  <input type="number" matInput placeholder="Services Rate per quantity" currencyMask
                    formControlName="serviceRatePerQuanity" readonly="readonly" />
                </mat-form-field>
              </div>
              <div class="col-xl-1">
                <mat-form-field>
                  <span matPrefix>$&nbsp;</span>
                  <input type="string" currencyMask matInput placeholder="Override Rate" [readonly] = "!isOverrideRateVisible(i) || !(serviceEstimateType == '0' || serviceEstimateType == undefined || phaseItemType == 'template')"
                    formControlName="overrideRate" />
                </mat-form-field>
              </div>
              <div class="col-xl-12 spacing">
                <mat-form-field>
                  <textarea matInput placeholder="Activity Notes" formControlName="activityNotes"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <hr>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <button mat-icon-button type="button" *ngIf="phase === 'Plan' && items.length > 0" (click)="addPlanItem.emit()"
    color="primary" title="Add Plan Item">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="phase === 'Execute' && items.length > 0" (click)="addExecuteItem.emit()"
    color="primary" title="Add Execute Item">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="phase === 'Close' && items.length > 0" (click)="addCloseItem.emit()"
    color="primary" title="Add Close Item">
    <mat-icon>add</mat-icon>
  </button>
</mat-accordion>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRoleService } from '../service-role.service';
import { ProjectControlService } from '../../project-control/project-control.service';
import { ServiceRole, ProjectControl } from '../../models/service-role';
import { Location } from '@angular/common';
import { AdminService } from '../../admin.service';
import { GraphApiService } from '../../../shared/services/graph-api.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-service-role-edit',
  templateUrl: './service-role-edit.component.html',
  styleUrls: ['./service-role-edit.component.scss']
})
export class ServiceRoleEditComponent implements OnInit {

  serviceRoleFormGroup: UntypedFormGroup;
  projectControls: Array<ProjectControl>;
  employeeServiceRoles: UntypedFormArray;
  approvers: any[];
  componentId: any;
  serviceRole: ServiceRole;
  serviceRoleEstimateTypes: UntypedFormArray;
  savedServiceRoleEstimateTypes: any;
  constructor(private formBuilder: UntypedFormBuilder, private location: Location, private route: ActivatedRoute, private router: Router, private serviceRoleService: ServiceRoleService, private projectControlService: ProjectControlService, private adminServie: AdminService, private graphApiService: GraphApiService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.route.params
      .subscribe(routeParams => {
        const id = routeParams['id'];
        this.getProjectControls();
        this.componentId = id;
        if (this.componentId) {
          this.getServiceRoleData(id);
        }
      });
  }
  getServiceRoleData(id: string) {
    let fetchEmployees = this.adminServie.getEmployees();
    let fetchAdUsers = this.graphApiService.getUsers();
    let fetchServiceRole = this.serviceRoleService.getServiceRole(id);
    this.serviceRoleEstimateTypes = this.fb.array([]);
    this.savedServiceRoleEstimateTypes = [];
    forkJoin([fetchEmployees, fetchAdUsers, fetchServiceRole]).subscribe(results => {
      this.serviceRole = results[2];
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.approvers = results[0].filter(emp => azAdEmployees.find(azUser => azUser.id == emp.azureAdId));
      
      this.serviceRole?.serviceRoleEstimateTypes?.forEach((seType: any, index: number) => {
        this.addServiceRoleEstimateType(seType);
      });
      Object.assign(this.savedServiceRoleEstimateTypes, this.serviceRoleEstimateTypes.value);

      if (this.serviceRole.employeeServiceRoles && this.serviceRole.employeeServiceRoles.length > 0) {
        this.serviceRole.employeeServiceRoles.forEach(esr => {
          this.addApprover(esr);
        });
      } else {
        this.addApprover();
      }
      
      this.serviceRoleFormGroup = this.formBuilder.group({
        'createdByEmployeeId': [this.serviceRole.createdByEmployeeId],
        'createdDate': [this.serviceRole.createdDate],
        'projectControlId': [this.serviceRole.projectControl ? this.serviceRole.projectControl.projectControlId : null, Validators.required],
        'serviceRoleDescription': [this.serviceRole.serviceRoleDescription, Validators.required],
        'epicorPartNumberId': [this.serviceRole.epicorPartNumberId, Validators.required],
        'serviceRoleHourlyRate': [this.serviceRole.serviceRoleHourlyRate, Validators.required],
        'serviceRoleId': [this.serviceRole.serviceRoleId],
        'serviceRoleName': [this.serviceRole.serviceRoleName, Validators.required],
        'softDelete': [this.serviceRole.softDelete],
        'employeeServiceRoles': this.employeeServiceRoles,
        'serviceRoleEstimateTypes': [this.serviceRoleEstimateTypes.value.map((x: any) => x.serviceEstimateType)],
        'enableMultipleOverrides' : [this.serviceRole.enableMultipleOverrides || false]
      });
    });
  }
  submitForm() {
    if (this.serviceRoleFormGroup.valid) {
      let projectControl: ProjectControl;
      let serviceRole: ServiceRole = Object.assign({}, this.serviceRoleFormGroup.value);
      projectControl = this.projectControls.filter(pc => {
        return pc === this.serviceRoleFormGroup.controls['projectControlId'].value;
      })[0];
      serviceRole.serviceRoleEstimateTypes?.filter((x:any) => !x.serviceRoleId).forEach((seType: any, index: number) => {
        this.addServiceRoleEstimateType(seType);
      });
      const removableSeTypes = this.savedServiceRoleEstimateTypes.filter((sr: any) => !this.serviceRoleFormGroup.value.serviceRoleEstimateTypes.some((seType: any) => sr.serviceEstimateType === seType));
      removableSeTypes.forEach((itr: any) => {
        let itemIndex = this.serviceRoleEstimateTypes.value.findIndex((x: any) => x.id == itr.id);
        let item = this.serviceRoleEstimateTypes.value[itemIndex];
        if (item) {
          this.serviceRoleEstimateTypes.removeAt(itemIndex);
          this.serviceRoleService.removeServiceRoleEstimateType(item.id).subscribe(res => {
            
          });
        }
      })
        
      serviceRole.serviceRoleEstimateTypes = this.serviceRoleEstimateTypes.value;
      serviceRole.projectControl = projectControl;
      serviceRole.enableMultipleOverrides = this.serviceRoleFormGroup.value.enableMultipleOverrides;

      this.serviceRoleService.updateServiceRole(serviceRole)
        .subscribe(updateResult => {
          this.location.back();

        });
    }
  }
  addApprover(approver?: any) {
    if (this.employeeServiceRoles) {
    } else {
      this.employeeServiceRoles = this.formBuilder.array([]);
    }
    if (approver) {
      let isActiveEmployee = this.approvers.map(apr => apr.employeeId).indexOf(approver.employeeId) != -1 ? true : false;
      this.employeeServiceRoles.push(this.formBuilder.group({
        id: [approver.id],
        employeeId: [isActiveEmployee? approver.employeeId: null, Validators.required],
        serviceRoleId: [this.componentId],
        isResourceAssigner: [isActiveEmployee? approver.isResourceAssigner: false]
      }));
    } else {
      this.employeeServiceRoles.push(this.formBuilder.group({
        employeeId: [null, Validators.required],
        serviceRoleId: [this.componentId],
        isResourceAssigner: [false]
      }));
    }
  }
  addServiceRoleEstimateType(seType?: any) {
    let item: UntypedFormGroup;
    let serviceRoleEstimateType;
    if (typeof (seType) == 'number') {
      serviceRoleEstimateType = this.serviceRoleEstimateTypes.value.find((x: any) => x.serviceEstimateType == seType);
      if (serviceRoleEstimateType)
        item = this.fb.group({
          'id': [serviceRoleEstimateType.id],
          'serviceEstimateType': [serviceRoleEstimateType.serviceEstimateType],
          'serviceRoleId': [serviceRoleEstimateType.serviceRoleId]
        });
      else
        item = this.fb.group({
          'serviceEstimateType': [seType],
          'serviceRoleId': [this.serviceRole.serviceRoleId]
        });
    }
    else if (seType) {
      item = this.fb.group({
        'id': [seType.id],
        'serviceEstimateType': [seType.serviceEstimateType],
        'serviceRoleId': [seType.serviceRoleId]
      });
    }
    else {
      item = this.fb.group({
        'serviceEstimateType': ['']
      });
    }
    serviceRoleEstimateType ? "" : this.serviceRoleEstimateTypes.push(item);
  }
  removeApprover(index: number) {
    const id = this.employeeServiceRoles.at(index).value['id'];
    const employeeId = this.employeeServiceRoles.at(index).value['employeeId'];
    if (id && employeeId) {
      this.serviceRoleService.removeServiceRoleApprover(this.componentId, employeeId)
        .subscribe(res => {
          this.employeeServiceRoles.removeAt(index);
        });
    } else {
      this.employeeServiceRoles.removeAt(index);
    }
  }

  getProjectControls() {
    this.projectControlService.getProjectControls()
      .subscribe(res => {
        this.projectControls = res;
      });
  }
  isApproverDisabled(approverId: string) {
    return this.employeeServiceRoles.value.map((esr: any) => esr.employeeId).indexOf(approverId) >= 0;
  }
}


<div class="col-md-12">
  <mat-card>
    <mat-card-content>
      <div class="col-md-12">
        <h3>Advanced Search Filters</h3>
        <form *ngIf="states && cityFormControl && stateFormControl && radiusFormControl">

          <mat-form-field>
            <input id="geoState" type="text" required
                   placeholder="Select a State"
                   aria-label="State"
                   matInput
                   [formControl]="stateFormControl"
                   [matAutocomplete]="auto" autocomplete="off" />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" autocomplete="off" [panelWidth]="180" [displayWith]="displayAutoCompleteForState">
              <mat-option *ngFor="let state of statesAutoCompleteOptions | async" [value]="state" [id]="state.stateId" [matTooltip]="state.name">
                {{state.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-hint>
              Select a State
            </mat-hint>
            <mat-error *ngIf="stateFormControl.hasError('required')">
              State is required
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input id="geoCity" type="text" required
                   placeholder="Select a City"
                   aria-label="City"
                   matInput
                   [formControl]="cityFormControl"
                   [matAutocomplete]="autoCompleteCity" autocomplete="off" />
            <mat-autocomplete autoActiveFirstOption #autoCompleteCity="matAutocomplete" autocomplete="off" [panelWidth]="180" [displayWith]="displayAutoCompleteForCity.bind(this)">
              <mat-option *ngFor="let city of citiesAutoCompleteOptions | async" [value]="city.cityId" [id]="city.cityId" [matTooltip]="city.name">
                {{city.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-hint>
              Select a City
            </mat-hint>
            <mat-error *ngIf="cityFormControl.hasError('required')">
              City is required
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input id ="geoRadius" matInput type="number" placeholder="Select Radius/Distance" [formControl]="radiusFormControl" />
            <mat-error *ngIf="radiusFormControl.hasError('required')">
              Radius/Distance is required
            </mat-error>
          <mat-error *ngIf="!radiusFormControl.valid" class="error">
            Source addresses must be supplied
          </mat-error>          
          </mat-form-field>
          <mat-checkbox id="excludeNationwide" [checked]="excludeNationwide" (change)="onExcludeNationwideChange()">
            Exclude Nationwide
          </mat-checkbox>  
          <button (click)="resetFilters()" mat-icon-button type="button">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </div>
      <br />
      <mat-card>
        <mat-card-content>
          <app-business-partner-popup id="geoFencingPopupModal" [businessPartnerDataSource]="geofenceDataSource"
                                                [filteredPartners]="allPartners" [isGeoFence]="true" [isBPDataLoading]="isBPDataLoading"
                                                (selectedPartner)="closePopUp($event)" [isPopup]="isPopup" [geoFencedCities]="geoFencedCities">

          </app-business-partner-popup>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>

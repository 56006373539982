import { Tag, BusinessPartnerTag } from '../../tags/models/tag.model';

export class BusinessPartner {
  /*[DatabaseGenerated(DatabaseGeneratedOption.Identity)]
[Key]*/
  id: string;
  businessPartnerLegalName: string;
  isNationwide: boolean;
  /*[MaxLength(500)]
          [Url]*/
  websiteUri: string;
  overallRating: string | number | undefined | null;
  requestor: Requestor;
  supplierNumber: string;
  notes: string;
  liabilityLimit: number;
  workersCompLimit: number;
  scoreCardId: string;
  w9Date: Date;
  workersCompExpiration: Date;
  generalLiabilityExpirationDate: Date;
  primaryLocationId: string;
  businessPartnerStatus: BusinessPartnerStatus;
  primaryLocation: BusinessLocation;
  contacts: Array<ContactInfo>;
  additionalLocations: Array<BusinessLocation>;
  solutionPilarsBusinessPartners: Array<any>;
  serviceableStates: Array<ServiceableState>;
  serviceableCities: Array<ServiceableCity>;
  agreements: Array<BusinessAgreement>;
  locations: Array<any>;
  country: string;
  city: string;
  state: string;
  cyberSecurityLiabilityLimit: number;
  cyberSecurityExpirationDate: Date;
  businessPartnerTags: Array<BusinessPartnerTag>;
  statusNotes: string;
}
export class BusinessLocation {
  id: string;
  streetOne: string;
  streetTwo: string;
  stateId: string;
  cityId: string;
  city: string;
  state: string;
  zipCode: string;
  isInternational: boolean;
  country: string;
}
export class BusinessPartnerLocationModel {
  id: number;
  country: string;
}
export class ContactInfo {
  /*[DatabaseGenerated(DatabaseGeneratedOption.Identity)]
[Key]*/
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  /*[Phone]*/
  primaryPhoneNumber: string;
  /*[Phone]*/
  secondaryPhoneNumber: string;
  /*[Phone]*/
  faxNumber: string;
  isPrimaryContact: boolean;
}
export class BusinessAgreement {
  /*[DatabaseGenerated(DatabaseGeneratedOption.Identity)]
[Key]*/
  id: string;
  agreementType: AgreementType;
  signer: Signer;
  dateOnAgreement: Date;
  dateAgreementSent: Date;
  dateSignedByMit: Date;
}
export enum AgreementType {
  SubcontractorAgreement,

  ReverseSubcontractorAgreement,

  NonDisclosureAgreement,

  MutualNonDisclosureAgreement,

  ResellerAgreement,

  PartnerAgreement,

  SubProvidedAgreement,

  OtherAgreement
}
export enum Signer {

  SteveBickford,

  TomHoran,

  TimMcDermott,

  WilliamPatterson,

  ChristianPeterson,

  GailSchy,

  RochelleSlater
}
export enum Requestor {

  MichelleFuller,

  IvayloGeorgiev,

  EarleHolbrooks,

  JoeKolonko,

  ErickMichalski,

  ChristianPeterson,

  KristySholett,

  PaulaWalsh
}
export enum BusinessPartnerStatus {
  InProcess,

  Approved,

  DoNotUse,

  Renewed
}
export class ServiceableState {
  public id: string;
  businessPartnerId: string;
  public stateId: string;
  public state: State;
  public allCities: boolean;
}
export class ServiceableCounty {
  public CountyId: string;
  public Name: string;
  public StateId: string;
  public State: State;
}
export class ServiceableCity {
  public id: string;
  public cityId: string;
  public city: City;
  businessPartnerId: string;
}
export class State {
  public stateId: string;
  public name: string;
  public abbreviation: string;
  public cities: Array<City>;
  public counties: Array<County>;
}
export class County {
  public CountyId: string;
  public Name: string;
  public StateId: string;
  public State: State;
}
export class City {
  cityId: string;
  name: string;
  stateId: string;
  state: State;
  location: Geography;
}
export class Coordinates
{
  latitude: number;
  longitude: number
};
export class Geography {
  type: string;
  coordinates: Array<number>
};

<mat-card *ngIf="states">

  <mat-card-content *ngIf="businessPartnerForm">
    <mat-tab-group>
      <mat-tab label="Details">
        <form *ngIf="filteredRequestorEmployees" [formGroup]="businessPartnerForm" (ngSubmit)="submitPartner()">
          <div *ngIf="businessPartner">
            <div class="col-md-12">
              <mat-form-field>
                <input id="bpLegalName" matInput formControlName="businessPartnerLegalName"
                  placeholder="Business Partner Legal Name" />
                <mat-error *ngIf="businessPartnerForm.controls['businessPartnerLegalName'].hasError('required')">
                  Business
                  partner name is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Website URL" type="url" formControlName="websiteUri" />
                <mat-error *ngIf="businessPartnerForm.controls['websiteUri'].hasError('required')">
                  The
                  website
                  is
                  required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-select formControlName="requestor" placeholder="Requestor" disableOptionCentering>
                  <mat-option *ngFor="let opt of filteredRequestorEmployees" [value]="opt.id"
                    [disabled]="!opt.active">
                    {{opt.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="businessPartnerForm.controls['requestor'].hasError('required')">
                  The
                  requestor
                  is required
                </mat-error>
              </mat-form-field>
              <!-- <mat-form-field>
                  <mat-select multiple formControlName="requestorEmployeeId" placeholder="Requestor" multiple>
                      <mat-option *ngFor="let opt of filteredRequestorEmployees" [value]="opt.employeeId">
                          {{opt.firstName + ' ' + opt.lastName}}
                      </mat-option>
                  </mat-select>
              </mat-form-field> -->
              <!-- <mat-form-field>
                  <mat-select matInput placeholder="Serviceable Geography" multiple formControlName="serviceableStates">
                      <mat-option *ngFor="let state of states" [value]="state.stateId">{{state.abbreviation}}</mat-option>
                  </mat-select>
              </mat-form-field> -->

              <mat-form-field>
                <input matInput formControlName="supplierNumber" placeholder="Supplier Number" />
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="scoreCard" placeholder="Score Card" />
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="Status" formControlName="businessPartnerStatus" disableOptionCentering
                  id="bpStatus">
                  <mat-option [value]="0">In Process </mat-option>
                  <mat-option [value]="1">Approved - In Good Standing</mat-option>
                  <mat-option [value]="3">Approved - Awaiting Renewed COI</mat-option>
                  <mat-option [value]="2">Do Not Use/Accept</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="businessPartnerForm.controls['businessPartnerStatus'].value == 2" class="col-md-3">
                <textarea matInput formControlName="statusNotes" placeholder="Notes" id="statusNotes"></textarea>
                <mat-error *ngIf="businessPartnerForm.controls['statusNotes'].hasError('required')">
                  Notes
                  is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="solutionPilars">
              <h3>
                Solution Pillars
                <button mat-icon-button type="button" (click)="addSolutionPilarItem()" [disabled]="isViewOnly"
                  id="addSolPilar">
                  <mat-icon>add</mat-icon>
                </button>
              </h3>
              <div [formArrayName]="'solutionPilarsBusinessPartners'" class="col-md-12">
                <div class="" *ngFor="let item of solutionPilarsBusinessPartners.controls;let i = index"
                  [formGroupName]="i">
                  <mat-form-field>
                    <mat-select formControlName="solutionPilarId" placeholder="Solution Pillar" disableOptionCentering
                      [id]="'solutionPilar'+(i+1)">
                      <mat-option *ngFor="let solutionPilar of solutionPilars" [value]="solutionPilar.solutionPilarId"
                        [disabled]="isSolutionPilarDisabled(solutionPilar.solutionPilarId)">
                        {{solutionPilar.solutionPilarName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button mat-icon-button type="button" (click)="removeSolutionPilar(i)" [disabled]="isViewOnly">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="tags">
              <h3>
                Tags
                <button mat-icon-button type="button" (click)="addTagItem()" [disabled]="isViewOnly" id="addTagItem">
                  <mat-icon>add</mat-icon>
                </button>
              </h3>
              <div [formArrayName]="'businessPartnerTags'" class="col-md-12">
                <div class="" *ngFor="let item of tagsFormArray.controls;let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <mat-select formControlName="tagId" placeholder="Tag" disableOptionCentering [id]="'bpTag'+(i+1)">
                      <mat-option *ngFor="let tag of tags" [value]="tag.id" [disabled]="isTagDisabled(tag.id)">
                        {{tag.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button mat-icon-button type="button" (click)="removeTag(i)" [disabled]="isViewOnly">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <h3>
                Serviceable States
                <button mat-icon-button type="button" (click)="addServiceableStateItem()" [disabled]="businessPartnerForm.controls.isNationwide.value || isViewOnly"
                  id="addServiceableState">
                  <mat-icon>add</mat-icon>
                </button>
              </h3>
              <!-- Nationwide Checkbox -->
              <mat-checkbox formControlName="isNationwide" (change)="onNationwideChange($event.checked)" id="nationwideCheckbox">
                Nationwide
              </mat-checkbox>
              <div *ngIf="!businessPartnerForm.controls.isNationwide.value" [formArrayName]="'serviceableStates'" class="col-md-12">
                <div class="" *ngFor="let item of serviceableStatesFormArray.controls;let i = index" 
                [formGroupName]="i">
                  <mat-form-field>
                    <mat-select matInput placeholder="Serviceable Geography" disableOptionCentering
                      (selectionChange)="onStateChange()" formControlName="stateId" [id]="'bpState'+(i+1)">
                      <mat-option *ngFor="let state of states" [value]="state.stateId" 
                      [disabled]="isStateDisabled(state.stateId)">
                        {{state.abbreviation}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="!item.get('allCities').value" class="example-full-width">
                    <input type="text" placeholder="Select Cities" aria-label="Select Cities" matInput
                      [matAutocomplete]="auto" [value]="displayAutoCompleteForServiceableCity(i)" [id]="'bpCity'+(i+1)">
                  </mat-form-field>
            
                  <mat-autocomplete #auto="matAutocomplete">
                    <div class="sticky-input">
                      <input type="text" placeholder="Search cities..." mat-input class="city-filter"
                        (input)="onInputChange($event.target.value, i)">

                    </div>
                    <mat-option class="fake-disable" [disabled]="true"
                      *ngFor="let city of getServiceableCities(i); let j = index" [value]="city.cityId">
                      <div (click)="$event.stopPropagation()">

                        <mat-checkbox [id]="'bpCityCheckBox'+ j" [checked]="city.selected"
                          (change)="optionClicked($event, city, i)">
                          {{ city.name }}
                        </mat-checkbox>
                      </div>
                    </mat-option>
                    <mat-option disabled *ngIf="getServiceableCities(i)?.length === 0">
                      No cities found
                    </mat-option>
                  </mat-autocomplete>
                  <mat-checkbox formControlName="allCities" [id]="'coverEntireState' + i"
                    (change)="allCitiesOptionClicked($event, i)">
                    All Cities
                  </mat-checkbox>
            
                  <button mat-icon-button type="button" (click)="removeServiceableState(i)" [disabled]="isViewOnly"
                    [id]="'removeServiceableState'+(i+1)">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div style="display: inline-block;">
                <h3>
                  Contact Info
                  <button id="addContactInfoBtn" mat-icon-button type="button" (click)="addContactItem()"
                    [disabled]="isViewOnly">
                    <mat-icon>add</mat-icon>
                  </button>
                </h3>
                <div class="col-md-12">
                </div>
                <div [formArrayName]="'contacts'" class="col-md-12">
                  <div class="" *ngFor="let item of contacts.controls;let i = index" [formGroupName]="i">
                    <mat-form-field>
                      <input matInput type="text" placeholder="First Name" formControlName="firstName" />
                      <mat-error *ngIf="item.controls['firstName'].hasError('required')">
                        Mandatory Fields
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="text" placeholder="Last Name" formControlName="lastName" />
                      <mat-error *ngIf="item.controls['lastName'].hasError('required')">
                        Mandatory Fields
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="text" placeholder="Title/Role" formControlName="title" />
                    </mat-form-field>
                    <mat-form-field>
                      <input type="email" matInput formControlName="email" placeholder="Email" />
                    </mat-form-field>
                    <mat-form-field>
                      <input type="string" matInput formControlName="primaryPhoneNumber"
                        placeholder="Primary Phone Number" />
                      <mat-error *ngIf="item.controls['primaryPhoneNumber'].hasError('required')">
                        Mandatory Fields
                      </mat-error>
                      <mat-error *ngIf="item.controls['primaryPhoneNumber'].hasError('pattern')">
                        Phone number must be at least 10 digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input type="tel" matInput formControlName="secondaryPhoneNumber"
                        placeholder="Secondary Phone Number" />
                      <mat-error *ngIf="item.controls['secondaryPhoneNumber'].hasError('pattern')">
                        Phone number must be at least 10 digits
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input type="tel" matInput formControlName="faxNumber" placeholder="Fax Number" />
                    </mat-form-field>
                    <mat-checkbox formControlName="isPrimaryContact">Primary Contact?</mat-checkbox>
                    <button mat-icon-button (click)="removeContact(i)" type="button" [disabled]="isViewOnly">
                      <mat-icon>close</mat-icon>
                    </button>

                    <!-- <mat-form-field>
                        <input matInput formControlName="websiteUrl" placeholder="Website Url" />
                    </mat-form-field> -->
                  </div>
                </div>
              </div>
              <hr>
            </div>

            <div class="col-md-12">
              <h3>
                Primary Location
              </h3>
              <div class="col-md-12" formGroupName="primaryLocation"
                *ngIf="!primaryLocation.controls['isInternational'].value == true">
                <mat-checkbox class="full-width" formControlName="isInternational" id="isInternationalBtn">
                  International?
                </mat-checkbox>
                <mat-form-field>
                  <input matInput placeholder="Street" formControlName="streetOne" />
                  <mat-error *ngIf="primaryLocation.controls['streetOne'].hasError('required')">
                    A
                    street is
                    required
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Secondary Street" formControlName="streetTwo" />
                  <mat-error *ngIf="primaryLocation.controls['streetTwo'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-select id="bpStateDropdown" #stateInp matInput placeholder="State" formControlName="stateId"
                    disableOptionCentering>
                    <mat-option *ngFor="let state of states" [value]="state.stateId">
                      {{state.abbreviation}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="primaryLocation.controls['stateId'].hasError('required')">
                    A state
                    is
                    required
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input id="bpCityDropdown" type="text" placeholder="Select a City" aria-label="City" matInput
                    formControlName="cityId" [matAutocomplete]="autoCompleteCity" autocomplete="off" />
                  <mat-autocomplete autoActiveFirstOption #autoCompleteCity="matAutocomplete" autocomplete="off"
                    [panelWidth]="180" [displayWith]="displayAutoCompleteForCity.bind(this)">
                    <mat-option *ngFor="let city of citiesAutoCompleteOptions | async" [value]="city.cityId"
                      [id]="city.cityId" [matTooltip]="city.name">
                      {{city.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="primaryLocation.controls['cityId'].hasError('required')">
                    A city is
                    required
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input type="string" matInput placeholder="Zip Code" formControlName="zipCode" id="zipCode" />
                  <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('required')">
                    A zip
                    code is
                    required
                  </mat-error>
                  <mat-error
                    *ngIf="!primaryLocation.controls['zipCode'].hasError('maxlength') && primaryLocation.controls['zipCode'].hasError('pattern')">
                    Please enter a valid zip code either in xxxxx or xxxxx-xxxx digits format
                  </mat-error>
                  <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('maxlength')">
                    Max Limit 10 Characters only
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12" formGroupName="primaryLocation"
                *ngIf="primaryLocation.controls['isInternational'].value == true">
                <mat-checkbox class="full-width" formControlName="isInternational" id="isInternationalBtn">
                  International?
                </mat-checkbox>
                <mat-form-field>
                  <input matInput placeholder="Address Line1" formControlName="streetOne" />
                  <mat-error *ngIf="primaryLocation.controls['streetOne'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Address Line2" formControlName="streetTwo" />
                  <mat-error *ngIf="primaryLocation.controls['streetTwo'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="City" formControlName="city" />
                  <mat-error *ngIf="primaryLocation.controls['city'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Province/State/County" formControlName="state" />
                  <mat-error *ngIf="primaryLocation.controls['state'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input type="string" matInput placeholder="Zip/Postal Code" formControlName="zipCode" id="zipCode" />
                  <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                  <mat-error
                    *ngIf="!primaryLocation.controls['zipCode'].hasError('maxlength') && primaryLocation.controls['zipCode'].hasError('pattern')">
                    Please enter a valid zipcode
                  </mat-error>
                  <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('maxlength')">
                    Max Limit 10 Characters only
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Country" formControlName="country" />
                  <mat-error *ngIf="primaryLocation.controls['country'].hasError('required')">
                    Mandatory Fields
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12">
              <div>
                <h3>
                  Additional Locations
                  <button mat-icon-button id="addLocationBtn" type="button" (click)="addAddressItem()"
                    [disabled]="isViewOnly">
                    <mat-icon>add</mat-icon>
                  </button>
                </h3>
                <div [formArrayName]="'additionalLocations'" class="col-md-12">
                  <div class="" *ngFor="let item of addresses.controls;let i = index" [formGroupName]="i">
                    <app-business-partner-location-item [formArray]="addresses" [index]="i">
                    </app-business-partner-location-item>
                    <button mat-icon-button (click)="removeAddressItem(i)" type="button" [disabled]="isViewOnly">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <hr>
            </div>
            <!-- <input type="hidden" [value]="stateInp.value" formControlName="businessPartnerLocationState" placeholder="State" /> -->
            <!-- <div class="col-md-12">
                <mat-form-field>
                    <mat-select matInput formControlName="businessPartnerResourceType" placeholder="Resource Type">
                        <mat-option [value]="0">MIT</mat-option>
                        <mat-option [value]="1">BP</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <div class="col-md-12">
              <div>
                <h3>
                  Agreements
                  <button mat-icon-button type="button" (click)="addAgreementItem()" [disabled]="isViewOnly">
                    <mat-icon>add</mat-icon>
                  </button>
                </h3>
                <div [formArrayName]="'agreements'" class="col-md-12">
                  <div class="" *ngFor="let item of businessAgreements.controls;let i = index" [formGroupName]="i">
                    <mat-form-field>
                      <mat-select matInput formControlName="agreementType" disableOptionCentering
                        placeholder="Agreement Type">
                        <mat-option [value]="0">Subcontractor Agreement</mat-option>
                        <mat-option [value]="1">Reverse Subcontractor Agreement</mat-option>
                        <mat-option [value]="2">Non-Disclosure Agreement</mat-option>
                        <mat-option [value]="3">Mutual Non-Disclosure Agreement</mat-option>
                        <mat-option [value]="4">Reseller Agreement</mat-option>
                        <mat-option [value]="5">Partner Agreement</mat-option>
                        <mat-option [value]="6">Sub Provided Agreement</mat-option>
                        <mat-option [value]="7">Other Agreement</mat-option>
                      </mat-select>
                    </mat-form-field>


                    <mat-form-field>
                      <input matInput formControlName="dateOnAgreement" autocomplete="off"
                        [matDatepicker]="dateOfAgreement" placeholder="Date On Agreement" />
                      <mat-datepicker-toggle matSuffix [for]="dateOfAgreement">
                      </mat-datepicker-toggle>
                      <mat-datepicker #dateOfAgreement></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="dateAgreementSent" autocomplete="off"
                        [matDatepicker]="dateAgreementSent" placeholder="Date Agreement Sent" />
                      <mat-datepicker-toggle matSuffix [for]="dateAgreementSent">
                      </mat-datepicker-toggle>
                      <mat-datepicker #dateAgreementSent></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput formControlName="dateSignedByMit" autocomplete="off"
                        [matDatepicker]="dateSignedByMitPicker" placeholder="Date Signed By MIT" />
                      <mat-datepicker-toggle matSuffix [for]="dateSignedByMitPicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #dateSignedByMitPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-select formControlName="signer" placeholder="Approved By" disableOptionCentering>
                        <mat-option *ngFor="let opt of filteredSignedByEmployees" [value]="opt.id" [disabled]="!opt.active">
                          {{opt.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field>
                        <mat-select formControlName="signByEmployeeId" placeholder="Signed By">
                            <mat-option *ngFor="let opt of filteredSignedByEmployees" [value]="opt.employeeId">
                                {{opt.firstName + ' ' + opt.lastName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <button mat-icon-button type="button" (click)="removeAgreementItem(i)" [disabled]="isViewOnly">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <hr>
            </div>

            <div class="col-md-12">

              <mat-form-field>
                <input matInput formControlName="generalLiabilityExpirationDate" autocomplete="off"
                  [matDatepicker]="insuranceDateOfCertificatePicker" placeholder="COI General Liability Exp" />
                <mat-datepicker-toggle matSuffix [for]="insuranceDateOfCertificatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #insuranceDateOfCertificatePicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <input matInput [matDatepicker]="workmansCompPicker" placeholder="COI Workers Comp Exp"
                  formControlName="workersCompExpiration" autocomplete="off" />
                <mat-datepicker-toggle matSuffix [for]="workmansCompPicker"></mat-datepicker-toggle>
                <mat-datepicker #workmansCompPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <span matPrefix>$&nbsp;</span>
                <input matInput currencyMask placeholder="COI Liability Limit" formControlName="liabilityLimit" />
              </mat-form-field>

              <mat-form-field>
                <span matPrefix>$&nbsp;</span>
                <input matInput currencyMask placeholder="COI Workers Comp Limit" formControlName="workersCompLimit" />
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="w9Date" [matDatepicker]="dateOfW9" autocomplete="off" id="dateOfW9"
                  placeholder="Select Date of W8/W9" />
                <mat-datepicker-toggle matSuffix [for]="dateOfW9"></mat-datepicker-toggle>
                <mat-datepicker #dateOfW9></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <h4>Cyber Risk Insurance</h4>
              <mat-form-field>
                <input matInput [matDatepicker]="cyberSecurityExpirationDate" autocomplete="off"
                  placeholder="Cyber Risk Insurance Exp" formControlName="cyberSecurityExpirationDate" />
                <mat-datepicker-toggle matSuffix [for]="cyberSecurityExpirationDate">
                </mat-datepicker-toggle>
                <mat-datepicker #cyberSecurityExpirationDate></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <span matPrefix>$&nbsp;</span>
                <input matInput currencyMask placeholder="Cyber Risk Insurance Liability Limit"
                  formControlName="cyberSecurityLiabilityLimit" />
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field class="wide">
                <textarea matInput formControlName="notes" placeholder="Notes" rows="10"></textarea>
              </mat-form-field>
            </div>
          </div>
          <button mat-raised-button type="submit" [disabled]="disableSubmit || isViewOnly">Submit</button>
        </form>
      </mat-tab>
      <mat-tab label="Scorecard">
        <div class="mat-typography row">
          <div class="col-md-12">
            <h1>Overall: {{overallRating | number :'1.2-2'}}</h1>
          </div>
          <div class="col-md-12">
            <h1>Averages</h1>
          </div>
          <br>
          <div class="col-md-12">
            <!-- <mat-form-field>
                <mat-label>Select Solution Pillar</mat-label>
                <mat-select>
                    <mat-option *ngFor="let solutionPillar of solutionPilars">
                        {{solutionPillar.solutionPilarName}}</mat-option>
                </mat-select>
            </mat-form-field> -->
          </div>
          <mat-divider></mat-divider>
          <div class="col-md-2">
            <h2>Invoicing</h2>
            <p [class.disable-mat-cell]="isViewOnly">
              Quality:
              {{ isNan(businessPartnerScoreCardAverage.invoicingQualityAverage) ? "" :
              businessPartnerScoreCardAverage.invoicingQualityAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
            <p [class.disable-mat-cell]="isViewOnly">
              Timeliness:
              {{ isNan(businessPartnerScoreCardAverage.invoicingTimelinessAverage) ? "":
              businessPartnerScoreCardAverage.invoicingTimelinessAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
          </div>
          <div class="col-md-2">
            <h2>Pre-Sales</h2>
            <p [class.disable-mat-cell]="isViewOnly">
              Acurracy And Detail:
              {{ isNan(businessPartnerScoreCardAverage.preSalesAcurracyAndDetailAverage) ? "":
              businessPartnerScoreCardAverage.preSalesAcurracyAndDetailAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
            <p [class.disable-mat-cell]="isViewOnly">
              Timeliness:
              {{isNan(businessPartnerScoreCardAverage.preSalesTimelinessAverage) ? "" :
              businessPartnerScoreCardAverage.preSalesTimelinessAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
          </div>
          <div class="col-md-2">
            <h2>Post-Sales</h2>
            <p [class.disable-mat-cell]="isViewOnly">
              Skills And Quality:
              {{isNan(businessPartnerScoreCardAverage.postSalesSkillsAndQualityAverage) ? "":
              businessPartnerScoreCardAverage.postSalesSkillsAndQualityAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
            <p [class.disable-mat-cell]="isViewOnly">
              Timeliness:
              {{isNan(businessPartnerScoreCardAverage.postSalesTimelinessAverage) ? "":
              businessPartnerScoreCardAverage.postSalesTimelinessAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
          </div>
          <div class="col-md-2">
            <h2>Documentation</h2>
            <p [class.disable-mat-cell]="isViewOnly">
              Quality:
              {{isNan(businessPartnerScoreCardAverage.documentationQualityAverage) ? "":
              businessPartnerScoreCardAverage.documentationQualityAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
            <p [class.disable-mat-cell]="isViewOnly">
              Timeliness:
              {{isNan(businessPartnerScoreCardAverage.documentationTimelinessAverage) ? "":
              businessPartnerScoreCardAverage.documentationTimelinessAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
          </div>
          <div class="col-md-2">
            <h2>Communication</h2>
            <p [class.disable-mat-cell]="isViewOnly">
              Quality:
              {{isNan(businessPartnerScoreCardAverage.communicationQualityAverage) ? "":
              businessPartnerScoreCardAverage.communicationQualityAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
            <p [class.disable-mat-cell]="isViewOnly">
              Timeliness:
              {{isNan(businessPartnerScoreCardAverage.communicationTimelinessAverage) ? "":
              businessPartnerScoreCardAverage.communicationTimelinessAverage }}
              <!-- <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon>
              <mat-icon ckass="md-18">star</mat-icon> -->
            </p>
          </div>
        </div>
        <div class="row">

          <div class="col-md-12" *ngIf="scorecardDatasource">
            <h2>Scorecards</h2>
            <mat-table [dataSource]="scorecardDatasource" [disabled]=true>
              <ng-container matColumnDef="projectName">
                <mat-header-cell *matHeaderCellDef> Project Name </mat-header-cell>
                <mat-cell *matCellDef="let row" [class.disable-mat-cell]="isViewOnly">
                  {{row.serviceProject.projectName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="solutionPillar">
                <mat-header-cell *matHeaderCellDef> Solution Pillar </mat-header-cell>
                <mat-cell *matCellDef="let row" [class.disable-mat-cell]="isViewOnly">
                  {{row.solutionPilar.solutionPilarName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
                <mat-cell *matCellDef="let row" [class.disable-mat-cell]="isViewOnly">
                  {{row.createdByEmployee.firstName}}
                  {{row.createdByEmployee.lastName}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
                <mat-cell *matCellDef="let row" [class.disable-mat-cell]="isViewOnly"> {{row.createdDate | date}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let row" [class.disable-mat-cell]="isViewOnly">
                  <button type="button" mat-icon-button (click)="openBusinessPartnerScorecardWithData(row)">
                    <mat-icon>search</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

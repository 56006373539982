import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GraphApiService {
  baseUri: string | undefined = environment.protectedResources.find(pr => pr.name === 'graphApi')?.resourceUri;
  constructor(private httpClient: HttpClient) { }
  getApplications(): Observable<any[]> {
    return this.httpClient.get<any>(`${this.baseUri}/v1.0/applications?$top=999`).pipe(map(u => { return u.value; }));
  }
  getApplicationUsers(): Observable<any[]> {
    let id = environment.enterpriseApplicationId;
    return this.httpClient.get<any>(`${this.baseUri}/v1.0/servicePrincipals/${id}/appRoleAssignedTo?$top=999`)
      .pipe(map(u => { return u.value; }));
  }
  getUsers(): Observable<any[]> {
    let id = environment.enterpriseApplicationId;
    return this.httpClient.get<any>(`${this.baseUri}/v1.0/${id}/users?$top=999&$select=accountEnabled,displayName,givenName,jobTitle,mail,userPrincipalName,surname,id&$filter=endsWith(userPrincipalName,'@meridianitinc.com')or endswith(userPrincipalName,'themeridian.com')&$count=true`)
    .pipe(map(u => {
      return u.value.filter((x: any) => {
          const emailDomain = x.mail?.substring(x.mail.lastIndexOf("@") + 1);
          return emailDomain === "meridianitinc.com" || emailDomain === "themeridian.com";
      });
  }));
  }
  getUser(id: string): Observable<any[]> {
    return this.httpClient.get<any>(`${this.baseUri}/v1.0/users/${id}?$select=accountEnabled,displayName,givenName,jobTitle,mail,userPrincipalName,surname,id,mobilePhone`)
      .pipe(map(u => { return u; }));
  }
  getApplicationRoles(): Observable<any[]> {
    return this.httpClient.get<any>(`${this.baseUri}/v1.0/applications/${environment.applicationId}?$top=999`)
      .pipe(map(a => a.appRoles));
  }
  assignUserToApplication(userId: string, applicationRoleId: string) {
    let body = {
      principalId: userId,
      resourceId: environment.enterpriseApplicationId,
      appRoleId: applicationRoleId
    };
    return this.httpClient.post(`${this.baseUri}/v1.0/servicePrincipals/${environment.enterpriseApplicationId}/appRoleAssignedTo`, body)
  }
  unassignUserToApplication(applicationRoleId: string) {
    return this.httpClient.delete(`${this.baseUri}/v1.0/servicePrincipals/${environment.enterpriseApplicationId}/appRoleAssignments/${applicationRoleId}`)
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { BusinessPartner, City } from '../../../business-partners/models/bp-model';
import { BusinessPartnerDataSource } from '../../../business-partners/models/business-partner-datatable.model';
import { GeoFencingService } from '../../services/geo-fencing.service';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'app-geo-fencing-popup',
  templateUrl: './geo-fencing-popup.component.html',
  styleUrls: ['./geo-fencing-popup.component.scss']
})
export class GeoFencingPopupComponent implements OnInit {

  cities: Array<City> | null;
  excludeNationwide: boolean = false;
  geofenceDataSource: BusinessPartnerDataSource;
  allPartners: Array<BusinessPartner>;
  isLoading: boolean;
  isPopup: boolean;
  errorText: string;
  clientData: any;
  isBPDataLoading: boolean;
  filteredPartners: Array<BusinessPartner>;
  geoFencedCities: Array<any>;
  states: Array<any>;

  @ViewChild("autoCompleStateControl") autocompleteState: MatAutocompleteTrigger;
  isAutoCompStateOpened: boolean;

  selectedState: any;
  selectedCity: any;

  statesAutoCompleteOptions: Observable<Array<any>>;

  cityFormControl: UntypedFormControl;
  stateFormControl: UntypedFormControl;
  radiusFormControl: UntypedFormControl;
  citiesAutoCompleteOptions: Observable<any[]>;


  constructor(public dialogRef: MatDialogRef<GeoFencingPopupComponent>,
    private geoFencingService: GeoFencingService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {
      this.allPartners = this.data.filteredPartners;
      this.states = this.data.states;
      if (this.data?.clientData) {
        this.selectedState = this.states?.find(s => s.stateId == this.data.clientData.stateId);
        this.selectedCity = this.data.clientData.city;
        if (this.selectedState) {
          this.updateCitiesList(this.selectedState.stateId);
        }

      }
    }
    this.stateFormControl = new UntypedFormControl(this.selectedState ? this.selectedState : null, Validators.required);
    this.cityFormControl = new UntypedFormControl(this.selectedCity ? this.selectedCity : null, Validators.required);
    this.radiusFormControl = new UntypedFormControl(null, Validators.required);

    this.statesAutoCompleteOptions = this.stateFormControl.valueChanges
      .pipe(map(updatedSelectedState => {
        this.cities = null;
        this.selectedCity = null;
        if (updatedSelectedState) {
          this.cityFormControl.reset();
          if (typeof updatedSelectedState == 'string') {
            this.resetData();
            return this.states.filter(s => s.name.toLowerCase().includes(updatedSelectedState.toLowerCase()));
          } else {
            this.selectedState = updatedSelectedState;
            this.updateCitiesList(updatedSelectedState.stateId);
            return [];
          }
        } else {
          this.resetData();
          return this.states;
        }
      }));

    this.citiesAutoCompleteOptions = this.cityFormControl.valueChanges
      .pipe(map(updatedSelectedCity => {
        if (this.cities) {
          if (updatedSelectedCity) {
            if (typeof updatedSelectedCity == 'string') {
              this.resetData();
              return this.cities.filter(s => s.name.toLowerCase().includes(updatedSelectedCity.toLowerCase()));
            } else {
              this.selectedCity = updatedSelectedCity;
              return [];
            }
          } else {
            this.resetData();
            return this.cities;
          }
        }
        return [];
      }));

    this.radiusFormControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(radiusvalue => {
        if (radiusvalue) {
          if (this.selectedCity) {
            this.updateBps();
          }
        }
        else {
          this.resetData();
        }
      });
    this.cityFormControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(updatedSelectedCity => {
        if (updatedSelectedCity) {
          this.selectedCity = updatedSelectedCity;
          if (this.radiusFormControl.value) {
            this.updateBps();
          }
          else {
            this.resetData();
          }
        }
      });
    this.isLoading = false;
    this.isBPDataLoading = false;
    this.isPopup = this.data.isPopup;

    this.states = this.data?.states;
    this.filteredPartners = this.data.filteredPartners;

  }
  displayAutoCompleteForCity(input: any){
    const city = this.cities?.find( city => city.cityId == input);
    return city && city.name ? city.name : '';
  }
  displayAutoCompleteForState(state: any) {
    return state && state.name ? state.name : '';
  }
  updateCitiesList(stateId: string) {
    this.geoFencingService.getCitiesByStateId(stateId)
      .subscribe(cityResult => {
        this.cities = cityResult;
      });
  }
  updateBps() {
    let city: City | undefined = this.cities?.find(x => x.cityId == this.selectedCity);
    if (city?.location != null) {
      let coordinates = city.location.coordinates;
      this.isBPDataLoading = true;
      this.geoFencingService.getGeoFencedCities(coordinates[0], coordinates[1], this.radiusFormControl.value)
        .subscribe(res => {
          if (this.isBPDataLoading) {
            this.geoFencedCities = res;
            // Filter out nationwide partners if excludeNationwide is true
          if (this.excludeNationwide) {
            this.geoFencedCities = this.geoFencedCities.filter(city => !city.IsNationwide);
          }
            this.fetchTableData();
          }
        }, (error) => {
          this.isBPDataLoading = false;
        });
    } else {
      this.setTableData([]);
    }
  }
  fetchTableData() {
    let includedBps = new Array<BusinessPartner>();
  
    // Handle nationwide records first
    this.allPartners.forEach(bp => {
      if (!this.excludeNationwide && bp.isNationwide) {
        includedBps.push(bp);
      }
    });
  
    // Process non-nationwide records
    this.allPartners.forEach(bp => {
      bp.serviceableCities.forEach(bpsc => {
        if(includedBps.filter(ibp => ibp.id == bp.id).length == 0){
          if (this.geoFencedCities.filter(gfc => gfc.cityId == bpsc.cityId).length > 0) {
            bp.serviceableStates = bp.serviceableStates.filter(s => s.stateId == bpsc.city.stateId);
            includedBps.push(bp);
            return;
          }else if (this.geoFencedCities.filter(gfc => gfc.stateId == bpsc.city.stateId && bpsc.city.name.includes('All') && bpsc.city.name.includes('cities')).length > 0) {
            this.geoFencedCities.push(bpsc.city);
            bp.serviceableStates = bp.serviceableStates.filter(s => s.stateId == bpsc.city.stateId);
            includedBps.push(bp);
            return;
          }
        }
      });
    });
    this.setTableData(includedBps);
    this.isBPDataLoading = false;
  }
  
  // Override this method when the checkbox or other data changes
  onExcludeNationwideChange() {
    this.excludeNationwide = !this.excludeNationwide;
    if (this.radiusFormControl.value && this.selectedCity) {
      this.updateBps();
    } else {
      this.resetData();
    }
  }
  
  resetFilters() {
    this.excludeNationwide = false;
    this.cities = [];
    this.cityFormControl.reset();
    this.stateFormControl.reset();
    this.radiusFormControl.reset();
    this.resetData();
  }
  

  resetData() {
    this.setTableData([]);
  }
  setTableData(data: Array<BusinessPartner>) {
    this.geofenceDataSource = new BusinessPartnerDataSource();
    this.geofenceDataSource.setData(data);
  }


  getCitiesByStateId(stateId: string) {
    this.geoFencingService.getCitiesByStateId(stateId)
      .subscribe(res => {
        this.cities = res;
      });
  }
  checkForValidOrigin(originControl: AbstractControl) {
    this.resetData();
    this.geoFencingService.checkForValidOrigin(originControl.value).subscribe(res => {
      if (res) {
        originControl.setErrors(null);
        this.updateBps();
      }
      else {
        this.resetData();
        originControl.setErrors({ notValid: true });
      }
    }, (error) => {
    })
  }

  closePopUp(data: any) {
    this.dialogRef.close(data);
  }
}
